<template>
  <div>
    <v-toolbar
      flat
      color="white"
    >
      <!--  Search Box -->
      <v-form
        @submit.prevent="runSearch"
      >
        <v-text-field
          v-model="search"
          prepend-icon="fal fa-search"
          :append-icon="search ? 'fal fa-times-circle' : undefined"
          single-line
          hide-details
          @click:prepend="runSearch"
          @click:append="clearSearch"
        />
      </v-form>

      <v-spacer />

      <!-- New & Edit Dialog Box -->
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
      >
        <v-btn
          slot="activator"
          color="green lighten-1"
          dark
          class="mb-2"
        >
          New
        </v-btn>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.d_title"
                    label="Title"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.d_route"
                    label="Route"
                  />
                </v-flex>
                <v-textarea
                  v-model="editedItem.d_text"
                  label="Text"
                />
                <v-flex xs12 />
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color=""
              flat
              @click.native="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="secondary"
              @click.native="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Dialog Box -->
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Delete this record?</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  "{{ editedItem.d_title }}" will be deleted from the system!
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color=""
              flat
              @click.native="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click.native="deleteItem"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="helpTopicsStore.helpTopics"
      hide-actions
      class="elevation-0"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.id }}</td>
        <td class="text-xs">
          {{ props.item.d_title }}
        </td>
        <td class="text-xs">
          {{ props.item.d_route }}
        </td>
        <td class="text-xs">
          {{ props.item.d_text }}
        </td>
        <td class="justify-end layout px-3">
          <v-icon
            small
            class="mr-4"
            color="secondary"
            @click="editItem(props.item)"
          >
            far fa-pencil-alt
          </v-icon>
          <v-icon
            small
            color="error"
            @click="openDeleteDialog(props.item)"
          >
            fal fa-trash-alt
          </v-icon>
        </td>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="error"
        icon="far fa-exclamation-triangle"
      >
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HelpTopics',
  computed: {
    ...mapState({
      helpTopicsStore: (state) => state.helpTopicsStore,
    }),
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'd_title',
        },
        {
          text: 'Route',
          align: 'left',
          sortable: false,
          value: 'd_route',
        },
        {
          text: 'Text',
          align: 'left',
          sortable: false,
          value: 'd_text',
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'name',
          sortable: false,
        },
      ],
      list: [],
      editFlag: -1,
      editedItem: {
        id: '',
        d_route: '',
        d_text: '',
        d_title: '',
      },
      defaultItem: {
        id: '',
        d_route: '',
        d_text: '',
        d_title: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      /* Store authorized user in store */
      this.$store.dispatch('helpTopicsStore/fetch');
    },

    editItem(item) {
      this.editFlag = 1;
      this.editedItem = { ...item };
      this.dialog = true;
    },

    openDeleteDialog(item) {
      this.editedItem = { ...item };
      this.deleteDialog = true;
    },

    close() {
      this.dialog = false;
      this.deleteDialog = false;
      setTimeout(() => {
        this.editedItem = { ...this.defaultItem };
        this.editFlag = -1;
      }, 300);
    },

    save() {
      if (this.editFlag === 1) {
        this.$store.dispatch('helpTopicsStore/update', this.editedItem);
      } else {
        this.$store.dispatch('helpTopicsStore/create', this.editedItem);
      }
      this.close();
    },

    deleteItem() {
      const data = {
        id: this.editedItem.id,
      };
      this.$store.dispatch('helpTopicsStore/delete', data);
      this.close();
    },

    runSearch() {
      if (this.search) {
        const searchQuery = {
          search: this.search,
        };
        this.$store.dispatch('helpTopicsStore/query', searchQuery);
      } else {
        this.$store.dispatch('helpTopicsStore/fetch');
      }
    },

    clearSearch() {
      this.search = '';
      this.initialize();
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
